import $ from "jquery";
import AOS from "aos";
import "@fancyapps/fancybox";
import "@accessible360/accessible-slick";

const spd = 300; // default speed
let hashOffset = 228;
let navShown = false;

if ($("body.admin-bar").length) {
  hashOffset = hashOffset + 32;
}

// Trap hashed links & trigger as a click
if (window.location.hash) {
  const hash = window.location.hash;

  setTimeout(() => {
    $('a[href*="' + hash + '"]').trigger("click");
    const hashPos = $(hash).offset();
    window.scrollTo(0, hashPos.top - hashOffset);
  }, spd);
}

function scrolledPastLogo(e) {
  const el = document.querySelectorAll(e);

  if (el.length > 0) {
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();
    const elemTop = $(e).offset().top - 72;
    const elemBottom = elemTop + $(e).height();

    if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
      $("body > .nav").removeClass("nav--scrolled");
    } else {
      $("body > .nav").addClass("nav--scrolled");
    }
  }
}

window.setTimeout(() => {
  scrolledPastLogo(".header .logo");
}, spd * 3);

// Responsive Nav
$(".js-nav-toggle").on("click", e => {
  if (navShown === true) {
    $("body > .nav").removeClass("nav--active");
    $(".js-nav-toggle i")
      .removeClass("fa-times")
      .addClass("fa-bars");
    $(".nav .menu").fadeOut(spd, () => {
      $(".js-nav-toggle").removeClass("active");
    });
    navShown = false;
  } else {
    $("body > .nav").addClass("nav--active");
    $(".js-nav-toggle i")
      .addClass("fa-times")
      .removeClass("fa-bars");
    $(".nav .menu").fadeIn(spd, () => {
      $(".js-nav-toggle").addClass("active");
    });
    navShown = true;
  }
  e.preventDefault();
});

$(".js-heading > a").on("click", e => {
  $(e.currentTarget)
    .parent()
    .toggleClass("active");
  e.preventDefault();
});

// Detect video
$(".content iframe").each(function() {
  $(this)
    .parent()
    .addClass("has-video");
});

// Scroll to content
$(".js-scroll").on("click", () => {
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("main.content").offset().top - 48
    },
    spd * 3
  );
});

// Expandable content
$(".js-expand").on("click", function() {
  $(this)
    .toggleClass("active")
    .next()
    .slideToggle(spd * 2);
});

// Slick
$(".js-fader").slick({
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: false,
  fade: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  speed: 1200
});

// AOS
AOS.init({
  duration: spd * 3,
  offset: 0,
  once: true
});

// Reset AOS once animated
function unloadAOS(e) {
  $(e)
    .removeClass("aos-init aos-animate")
    .removeAttr("data-aos");
}

setTimeout(() => {
  unloadAOS(".nav");
  unloadAOS(".page-sections__section");
}, 1050);

$(window).scroll(() => {
  scrolledPastLogo(".header .logo");
});
